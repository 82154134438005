import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Dialog from '../../../common/Dialog';
import Snippet from '../../../common/Snippet';

const content = {
  body: 'Interactive dialog with options',
  button: 'Submit',
  cancel: 'Cancel',
  heading: 'Confirm your changes'
};

const options = [{
  label: 'Option one',
  value: '1'
}, {
  label: 'Option two',
  value: '2'
}, {
  label: 'Option three',
  value: '3'
}];

const Interactive = class Interactive extends React.Component {
  example = (
    <Dialog
      content={content}
      onCancel={() => console.log('Dialog cancelled.')}
      onConfirm={() => console.log('Dialog confirmed.')}
      onChange={e => console.log(e.target.value)}
      options={options}
      type="confirm"
    />
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.interactive}</h3>

        <article className="example">{this.example}</article>

        {/* TODO: Look at this instead of jsxToString (https://github.com/algolia/react-element-to-jsx-string) */}

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example)}
        />
      </section>
    )
  }
}

export default Interactive;
