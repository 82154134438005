import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Dropdown from '../../../common/Dropdown';
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  render() {
    const { translation } = this.props;

    const options = [{
      title: "Wikipedia",
      url: "#"
    }, {
      title: "Google",
      url: "#"
    }, {
      title: "Github",
      url: "#"
    }]

    const example = (
      <Dropdown 
        id="example-dropdown"
        triggerText="Dropdown Menu"
        options={options}
      />
    )

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(example)}
          name="primary"
          react={jsxToString(example)}
        />
      </section>
    )
  }
}

export default Primary;
