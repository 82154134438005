import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Snippet from '../../../common/Snippet';
import Switch from '../../../common/Switch';

const Primary = class Primary extends React.Component {
  example = (
    <div>
      <Switch
        defaultChecked={true}
        id={'testSwitch'}
        label={'A switch'}
        labelOff="Off"
        labelOn="On"
        onChange={e => console.log(e.target.checked)}
      />
    </div> 
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example, {useFunctionCode: true, functionNameOnly: true})}
        />
      </section>
    )
  }
}

export default Primary;
