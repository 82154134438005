/**
 * Check the validity of form fields on submit.
 * 
 * @exports
 * @function checkValidity
 * @param {event} e
 * @param {function} callback
 */
 export function checkValidity(e, callback) {
  e.preventDefault();

  const form = e.target;

  const invalidFields = form.querySelectorAll('input[required]:invalid, select[required]:invalid, textarea[required]:invalid, input[required][aria-invalid="true"]');

  // Mark the form as submitted
  form.className += ' form--submitted';

  // Remove any existing error messages
  const existingErrors = form.querySelectorAll('.form__err');

  for(let i = 0; i < existingErrors.length; i++) {
    existingErrors[i].remove();
  }

  // Handle validation
  if(invalidFields.length > 0) {
    // If there are invalid fields, insert error messages
    for(let i = 0; i < invalidFields.length; i++) {
      let isCustomSelect = false;
      let isCustomCheckbox = false;
      const errorMsg = invalidFields[i].getAttribute('data-errormsg');
      const errorEl = document.createElement('label');

      if(
        invalidFields[i].tagName.toLowerCase() === 'select' &&
        invalidFields[i].parentNode.className.indexOf('select') > -1
      ) {
        isCustomSelect = true;
      }

      if(
        invalidFields[i].parentNode.className.indexOf('cbox') > -1 ||
        invalidFields[i].parentNode.className.indexOf('rbox') > -1
      ) {
        isCustomCheckbox = true;
      }

      errorEl.innerHTML = errorMsg;
      errorEl.className = 'form__err';
      errorEl.setAttribute('for', invalidFields[i].id);
      errorEl.setAttribute('role', 'alert');

      if(!isCustomSelect && !isCustomCheckbox) {
        invalidFields[i].parentNode.insertBefore(errorEl, invalidFields[i].nextSibling);
      } else if(isCustomSelect) {
        invalidFields[i].parentNode.parentNode.insertBefore(errorEl, invalidFields[i].nextSibling);
      } else if(isCustomCheckbox) {
        invalidFields[i].parentNode.append(errorEl);
      }
    }
  } else {
    if(callback) {
      callback(e);
    }
  }
}

/**
 * Selector for any HTML element that can receive keyboard focus
 * 
 * @exports
 */
 export const focusable = "a[href]:not([tabindex='-1']),area[href]:not([tabindex='-1']),\
 input:not([disabled]):not([tabindex='-1']),select:not([disabled]):not([tabindex='-1']),\
 textarea:not([disabled]):not([tabindex='-1']),button:not([disabled]):not([tabindex='-1']),\
 iframe:not([tabindex='-1']),[tabindex]:not([tabindex='-1'])"

/**
 * Get a given URL segment
 * 
 * @exports
 * @function getUrlSegment
 * @param {integer} segment
 * @param {pathname} [pathname]
 * @returns {string}
 */
export function getUrlSegment(segment, pathname) {
  if (!pathname) {
    pathname = window.location.pathname;
  }

  let pathArray = pathname.split('/');

  if (pathArray[0] === '') {
    pathArray.splice(0, 1);
  }

  return pathArray[segment - 1];
}

/**
 * Check if a given element is a descendant of a given parent
 * 
 * @exports
 * @function isDescendant
 * @param {element} parent
 * @param {element} child
 * @returns {boolean}
 */
 export function isDescendant(parent, child) {
  let node = child.parentNode;

  while (node !== null) {
    if (node === parent) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}

/**
 * Convert sections to mm:ss
 * 
 * @function parseSeconds
 * @param {integer} seconds 
 * @returns {string}
 */
export function parseSeconds(seconds) {
  if(seconds === 0) {
    return '00:00';
  } else {
    return new Date(seconds * 1000).toISOString().substr(14, 5);
  }
}