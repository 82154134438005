import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Snippet from '../../../common/Snippet';
import Toast from '../../../common/Toast';

const Primary = class Primary extends React.Component {
  example = (
    <div>
      <Toast
        content="Informational toast message"
        onDismiss={() => console.log('Dimissed toast message')}
        timeout={600000}
      />

      <Toast
        content="Positive toast message"
        onDismiss={() => console.log('Dimissed toast message')}
        timeout={600000}
        type="positive"
      />

      <Toast
        content="Warning toast message"
        onDismiss={() => console.log('Dimissed toast message')}
        timeout={600000}
        type="warn"
      />

      <Toast
        content="Negative toast message"
        onDismiss={() => console.log('Dimissed toast message')}
        timeout={600000}
        type="negative"
      />
    </div>
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example example--wrap"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example, {useFunctionCode: true, functionNameOnly: true})}
        />
      </section>
    )
  }
}

export default Primary;
