import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Ghost = class Ghost extends React.Component {
  example = [
    <button className="btn btn--ghost" key="1">
      <svg aria-hidden="true" focusable="false" className="icon icon-plus" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3125 1H6.6875V6.6875H1V9.3125H6.6875V15H9.3125V9.3125H15V6.6875H9.3125V1Z"/></svg>

      Add
    </button>,

    <button className="btn btn--ghost" key="2">
      <svg aria-hidden="true" focusable="false" className="icon icon-plus" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3125 1H6.6875V6.6875H1V9.3125H6.6875V15H9.3125V9.3125H15V6.6875H9.3125V1Z"/></svg>

      <span className="meta">Add</span>
    </button>,

    <button className="btn btn--ghost btn--negative" key="3">
      <svg aria-hidden="true" focusable="false" className="icon icon-times" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M3.97833 2.12217L2.12217 3.97833L6.14384 8L2.12217 12.0217L3.97833 13.8778L8 9.85616L12.0217 13.8778L13.8778 12.0217L9.85616 8L13.8778 3.97833L12.0217 2.12217L8 6.14384L3.97833 2.12217Z"/></svg>

      Remove
    </button>,

    <button className="btn btn--ghost btn--negative" key="4">
      <svg aria-hidden="true" focusable="false" className="icon icon-times" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M3.97833 2.12217L2.12217 3.97833L6.14384 8L2.12217 12.0217L3.97833 13.8778L8 9.85616L12.0217 13.8778L13.8778 12.0217L9.85616 8L13.8778 3.97833L12.0217 2.12217L8 6.14384L3.97833 2.12217Z"/></svg>

      <span className="meta">Remove</span>
    </button>
  ]

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.ghost}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Ghost;
