import React from 'react';

// Dependencies
import Markdown from 'markdown-to-jsx';

const Usage = class Usage extends React.Component {
  render() {
    const { translation } = this.props;

    return (
      <section className="page__copy">
        <h2 className="page__subhead">
          {translation.usage.heading}
        </h2>

        <Markdown options={{ forceBlock: true }}>
          {translation.usage.intro}
        </Markdown>

        {translation.usage.note &&
          <aside className="note">
            <Markdown options={{ forceBlock: true }}>
              {translation.usage.note}
            </Markdown>
          </aside>
        }

        {translation.usage.sections &&
          translation.usage.sections.map(
            (section, i) =>
            <section key={i}>
              <h3>{section.heading}</h3>

              <Markdown options={{ forceBlock: true }}>
                {section.body}
              </Markdown>
            </section>
          )
        }
      </section>
    )
  }
}

export default Usage;
