import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Icons
import * as icons from '../../../ui/Icons';

// Components
import Snippet from '../../../common/Snippet';

const Sizing = class Sizing extends React.Component {
  example = (
    <div>
      <i className="icon--sm">
        <icons.people />
      </i>

      <i className="icon--md">
        <icons.people />
      </i>

      <i className="icon--lg">
        <icons.people />
      </i>

      <i className="icon--xl">
        <icons.people />
      </i>
    </div>
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.sizing}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          code={
            ReactDOMServer.renderToStaticMarkup(this.example)
              .replace(new RegExp('<div>', 'g'), '')
              .replace(new RegExp('</div>', 'g'), '')
          }
          name="primary"
          react={
            jsxToString(this.example)
              .replace(new RegExp('<div>', 'g'), '')
              .replace(new RegExp('</div>', 'g'), '')
              .replace(new RegExp('<people', 'g'), '<icons.people')
          }
        />
      </section>
    )
  }
}

export default Sizing;
