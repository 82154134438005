import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import MultiSelect from '../../../common/MultiSelect';
import Snippet from '../../../common/Snippet';

const options1 = [{
  label: 'Dog',
  value: 'dog'
}, {
  label: 'Cat',
  value: 'cat'
}, {
  label: 'Ocean Sunfish',
  value: 'ocean-sunfish'
}];

const options2 = [{
  label: 'Dogs',
  value: 'dogs',
  children: [{
    label: 'Pomeranian',
    value: 'pomeranian'
  }, {
    label: 'Husky',
    value: 'husky'
  }]
}, {
  label: 'Cat',
  value: 'cat'
}, {
  label: 'Ocean Sunfish',
  value: 'ocean-sunfish'
}, {
  label: 'Puppy',
  value: 'puppy'
}, {
  label: 'Octopus',
  value: 'octopus'
}, {
  label: 'Black bear',
  value: 'black-bear'
}, {
  label: 'Rhea',
  value: 'rhea'
}, {
  label: 'Kangaroo',
  value: 'kangaroo'
}];

const Primary = class Primary extends React.Component {
  render() {
    const { translation } = this.props;

    const example = (
      <fieldset className="form--inline form--inline-alt">
        <MultiSelect
          id="best-animal-1"
          label="Best animal"
          onChange={value => console.log(value)}
          options={options1}
          placeholder="Choose the best animal…"
          value={['dog']}
        />

        <MultiSelect
          id="best-animal-2"
          label="Best animal"
          onChange={value => console.log(value)}
          options={options2}
          placeholder="Choose the best animal…"
          value={[]}
        />
      </fieldset>
    );

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(example)}
          name="primary"
          react={jsxToString(example)}
        />
      </section>
    )
  }
}

export default Primary;
