import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Dialog from '../../../common/Dialog';
import Snippet from '../../../common/Snippet';

const content = {
  body: 'Your session is about to expire due to inactivity.',
  button: 'Extend session',
  cancel: 'Ignore',
  heading: 'Warning'
}

const Timeout = class Timeout extends React.Component {
  example = (
    <Dialog
      content={content}
      onTimeout={() => console.log('Dialog timed out!')}
      timeout={120}
      type="confirm"
    />
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.timeout}</h3>

        <article className="example">{this.example}</article>

        {/* TODO: Look at this instead of jsxToString (https://github.com/algolia/react-element-to-jsx-string) */}

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example)}
        />
      </section>
    );
  }
};

export default Timeout;