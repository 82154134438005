import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Action = class Action extends React.Component {
  example = [
    <form className="form panel panel--md" key="1">
      <fieldset>
        <legend>
          <h1 className="panel__title">Form Panel</h1>
        </legend>

        <ul className="form__fields">
          <li className="field field--half">
            <label className="form__lbl required" htmlFor="form-text1">Input label</label>

            <input
              id="form-text1"
              name="text_field1"
              type="text"
              placeholder="Optional placeholder text"
              required
              aria-required="true"
              data-errormsg="Error message with instructions to correct"
            />
          </li>
          <li className="field field--half">
            <label className="form__lbl required" htmlFor="form-text2">Input label</label>

            <input
              id="form-text2"
              name="text_field2"
              type="text"
              placeholder="Optional placeholder text"
              required
              aria-required="true"
              data-errormsg="Error message with instructions to correct"
            />
          </li>
          <li className="field">
            <label className="form__lbl required" htmlFor="form-select">Select label</label>

            <label className="select" htmlFor="form-select">
              <select
                id="example-select"
                name="select"
                required
                aria-required="true"
                data-errormsg="Error message with instructions to correct"
              >
                <option value="">Choose an option…</option>
                <option value="option one">Option one</option>
                <option value="option two">Option two</option>
                <option value="option three">Option three</option>
              </select>
            </label>
          </li>
          <li className="field">
            <label className="form__lbl" htmlFor="form-text3">Input label</label>

            <input
              id="form-text3"
              name="text_field3"
              type="text"
              placeholder="Optional placeholder text"
            />
          </li>
          <li className="field">
            <label className="form__lbl required" htmlFor="form-textarea">Textarea label</label>

            <textarea
              id="form-textarea"
              name="textarea"
              placeholder="Optional placeholder text"
              required
              aria-required="true"
              data-errormsg="Error message with instructions to correct"
            ></textarea>
          </li>
        </ul>
      </fieldset>

      <div className="panel__action">
        <button
          className="btn btn--outline"
          type="reset"
        >
          Cancel
        </button>

        <button
          className="btn"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>,
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.form}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="sizing"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Action;
