import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = [
    <ul
      className="grid"
      key="1"
      data-columns="3"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item grid__item--span-3"></li>
    </ul>
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
