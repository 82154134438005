import React from 'react';

// Helpers
import { isDescendant } from '../../helpers';

// Set up the initial state and reducer function
const initialState = {
  expanded: false,
  focusIndex: -1,
  long: false,
  pushLeft: false
};

/**
 * State reducer (used because there are several state properties)
 * 
 * @function reducer
 * @param {object} prevState
 * @param {object} state
 * @returns {object}
 */
const reducer = (prevState, state) => {
  return { ...prevState, ...state};
}

/**
 * Renders a dropdown menu component
 * 
 * @param {object} props
 * @property {string} [className]
 * @property {boolean} [disabled]
 * @property {boolean} [hideTriggerText]
 * @property {Function} [icon]
 * @property {string} id
 * @property {Array} options
 * @property {string} options.title The text for the option
 * @property {string} options.url The URL to point the option to if it is a link
 * @property {boolean} options.active True if currently viewing an option in the menu (logic in parent)
 * @property {Function} options.action The function to be triggered if the option is a button
 * @property {string} [triggerClassName] A custom className for the trigger button
 * @property {string} triggerText
 * @returns {Function}
 */
const Dropdown = props => {
  // Get values from props
  const {
    className,
    disabled,
    hideTriggerText,
    icon,
    id,
    options,
    triggerClassName,
    triggerText
  } = props;

  // Set up reducer
  const [state, dispatch] = React.useReducer(reducer, initialState);

  // Get the initial state values
  const { expanded, focusIndex, long, pushLeft } = state;

  // Create refs
  const dropdownRoot = React.useRef(null);
  const dropdownControl = React.useRef(null);
  const dropdownPanel = React.useRef(null);

  // Handler for checking if component is still mounted
  let _isMounted = true;

  // Create an array to hold focusable option elements.
  let _options = [];

  // Main useEffect hook
  React.useEffect(() => {
    // Listen for clicks
    document.addEventListener('click', handleClick);

    // Add focusable child elements to
    // the array of options.
    if (!_options.length) {
      _options = dropdownPanel.current.getElementsByClassName('dropdown__option');
    }

    // Update the focused option
    if (_options[focusIndex] && document.activeElement !== _options[focusIndex]) {
      _options[focusIndex].focus();
    }

    // Check if the dropdown is too far to the right
    if (
      dropdownPanel.current.getBoundingClientRect().right >= window.innerWidth &&
      _isMounted
    ) {
      dispatch({ data: { pushLeft: true }});
    }

    return () => {
      _isMounted = false;
      document.removeEventListener('click', handleClick)
    }
  }, [state]);

  /**
   * Hide when anything else is clicked
   * 
   * @function handleClick
   * @param {event} e 
   */
  const handleClick = e => {
    if (
      expanded &&
      !isDescendant(dropdownRoot.current, e.target) &&
      _isMounted
    ) {
      handleHide();
    }  
  }

  /**
   * Show the dropdown menu
   * 
   * @function handleExpand
   */
  const handleExpand = () => {
    if (_isMounted) {
      dispatch({ expanded: true })
    }
  };

  /**
   * Hide the dropdown menu
   * 
   * @function handleHide
   */
  const handleHide = () => {
    if (_isMounted) {
      dispatch({ expanded: false, focusIndex: -1 });
    }
  };

  /**
   * Fire a callback function for an option that is a button
   * 
   * @function handleAction
   * @param {Function} action
   */
  const handleAction = action => {
    action();
    handleHide();
  }

  /**
   * Handle keyboard navigation
   * 
   * @function handleKey
   * @param {event} e
   */
  const handleKey = (e) => {
    const last = _options.length - 1;

    const DOWN = 40;
    const ESCAPE = 27;
    const END = 35;
    const HOME = 36;
    const TAB = 9;
    const UP = 38;

    switch (e.keyCode) {
      case ESCAPE:
        handleHide();
        dropdownControl.current.focus();
        break;

      case HOME:
        if (expanded) {
          e.preventDefault();
          focusOption(0);
        }
        break;

      case END:
        if (expanded) {
          e.preventDefault();
          focusOption(last);
        }
        break;

      case TAB:
        if (expanded) {
          handleHide(); 
        }
        break;

      case UP:
        e.preventDefault();

        if (!expanded) {
          handleExpand(last);
        } else {
          if (focusIndex > 0) {
            focusOption(focusIndex - 1);
          } else {
            focusOption(last);
          }
        }
        break;

      case DOWN:
        e.preventDefault();

        if (!expanded) {
          handleExpand(0);
        } else {
          if (focusIndex < last) {
            focusOption(focusIndex + 1);
          } else {
            focusOption(0);
          }
        }
        break;

      default:
        break;
    }
  };

  /**
   * Focus an individual option
   * 
   * @function focusOption()
   * @param {number} focusIndex 
   */
  const focusOption = focusIndex => {
    if (_isMounted) {
      dispatch({ focusIndex });
    }
  };

  /**
   * Render the individual options
   * 
   * @function renderOption
   * @param {object} option
   * @returns {Function}
   */
  const renderOption = option => {
    let optionItem = null;

    if (
      option.title &&
      option.title.length > 35 &&
      !long &&
      _isMounted
    ) {
      dispatch({ long: true });
    }

    if (option.url) {
      // Link option
      optionItem = (
        <a
          className="dropdown__option"
          href={option.url}
        >
          {option.icon}
          {option.title}
        </a>
      );
    } else if (option.action) {
      // Button option
      optionItem = (
        <button
          className="dropdown__option"
          type="button"
          onClick={() => handleAction(option.action)}
        >
          {option.icon}
          {option.title}
        </button>
      );
    }

    return optionItem;
  }

  return (
    <div
      className={`dropdown${
        className ? ` ${className}` : ''
      }${
        expanded ? ' dropdown--open' : ''
      }${long ? ' dropdown--long' : ''}${
        pushLeft ? ' dropdown--push-left' : ''
      }`}
      id={id}
      ref={dropdownRoot}
      onKeyDown={handleKey}
    >
      <button
        aria-controls={`${id}-panel`}
        aria-expanded={expanded ? true : false}
        className={`dropdown__trigger ${
          triggerClassName ? triggerClassName : ''
        }`}
        disabled={disabled ? true : false}
        id={`${id}-control`}
        onClick={() => expanded ? handleHide() : handleExpand(0)}
        ref={dropdownControl}
        type="button"
      >
        {icon}

        <span className={hideTriggerText ? 'meta' : ''}>
          {triggerText}
        </span>
      </button>

      <ul
        className="dropdown__options"
        id={`${id}-panel`}
        ref={dropdownPanel}
      >
        {options && options.map((option, i) =>
          <li
            key={i}
            className={option.active ? 'dropdown__active-option' : ''}
          >
            {renderOption(option, i)}
          </li>
        )}
      </ul>
    </div>
  );
}

export default Dropdown;
