import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../../../translate/Translate';

// Components
import Primary from './Primary';
import Sizing from './Sizing';
import Action from './Action';
import Form from './Form';
import Usage from './Usage';

const Panel = observer(class Panel extends React.Component {
  render() {
		const { activeTab } = this.props;

		if(activeTab === 'examples') {
			return (
				<div className="wrap">
					<Primary {...this.props} />
					<Sizing {...this.props} />
					<Action {...this.props} />
					<Form {...this.props} />
				</div>
			)
		} else if(activeTab === 'usage') {
			return (
				<div className="wrap">
					<Usage {...this.props} />
				</div>
			)
		}
  }
})

export default translate('Panel')(Panel);
