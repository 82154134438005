import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = [
    <ul
      className="grid"
      key="1"
      data-columns="12"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="2"
      data-columns="11"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="3"
      data-columns="10"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="4"
      data-columns="9"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="5"
      data-columns="8"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="6"
      data-columns="7"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="7"
      data-columns="6"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="8"
      data-columns="5"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="9"
      data-columns="4"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="10"
      data-columns="3"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="11"
      data-columns="2"
    >
      <li className="grid__item"></li>
      <li className="grid__item"></li>
    </ul>,
    <ul
      className="grid"
      key="12"
      data-columns="1"
    >
      <li className="grid__item"></li>
    </ul>
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
