import React from "react";
import ReactDOMServer from "react-dom/server";

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Dialog from '../../../common/Dialog';
import Snippet from "../../../common/Snippet";

const content = {
  body: 'A passive dialog with an informational message.',
  button: 'Okay',
  heading: 'Dialog title'
}

const Primary = class Primary extends React.Component {
  example = (
    <Dialog
      content={content}
      onConfirm={() => console.log('Dialog confirmed.')}
    />
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.passive}</h3>

        <article className="example">{this.example}</article>

        {/* TODO: Look at this instead of jsxToString (https://github.com/algolia/react-element-to-jsx-string) */}

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example)}
        />
      </section>
    );
  }
};

export default Primary;
