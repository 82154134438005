import React from 'react';

/**
 * Renders a radio button component
 * 
 * @param {object} props
 * @property {string} className
 * @property {string} id
 * @property {string} legend
 * @property {string} name
 * @property {Function} onChange
 * @property {Array} options
 * @property {string} options.label
 * @property {boolean} [options.defaultChecked]
 * @property {boolean} [options.disabled]
 * @property {string} options.value
 * @property {string} value
 * @returns {Function}
 */
const RadioGroup = props => {
  // Get values from props
  const {
    className,
    id,
    legend,
    name,
    onChange,
    options,
    value
  } = props;

  return (
    <fieldset>
      <legend className="form__lbl">{legend}</legend>

      {options.map((option, i) =>
        <div
          className={`rbox${
            className ? ` ${className}` : ''
          }`}
          key={i}
        >
          <input
            aria-required={option.required}
            className="rbox__input"
            defaultChecked={option.value === value}
            disabled={option.disabled}
            id={`rbox-${id}-${i}`}
            onChange={onChange}
            name={name}
            required={option.required}
            type="radio"
            value={option.value}
          />
  
          <label
            className="rbox__lbl"
            htmlFor={`rbox-${id}-${i}`}
          >
            {option.label}
          </label>
        </div>
      )}

    </fieldset>
  )
}

export default RadioGroup;