import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Dependencies
import Markdown from 'markdown-to-jsx';

const Home = observer(class Home extends React.Component {
  render() {
    const { translation } = this.props;

    return (
      <main className="page">
        <div className="banner banner--hero">
          <h1>{translation.heading}</h1>
        </div>

        <div className="wrap">
          <div className="page__copy">
            <Markdown
              options={{ forceBlock: true}}
            >
              {translation.body}
            </Markdown>

            <h2>{translation.instructions.heading}</h2>

            <Markdown
              options={{ forceBlock: true}}
            >
              {translation.instructions.intro}
            </Markdown>

            {translation.instructions.sections.map(
              (section, i) =>
              <section key={i}>
                {section.heading &&
                  <h3>{section.heading}</h3>
                }

                <Markdown
                  options={{ forceBlock: true}}
                >
                  {section.body}
                </Markdown>
              </section>
            )}
          </div>
        </div>
      </main>
    )
  }
})

export default translate('Home')(Home);
