export let routes = {
	index: '/',
	components: '/components',
	componentsPages: {
		AutoGrow: '/components/autogrow',
		Button: '/components/button',
		Checkbox: '/components/checkbox',
		Dialog: '/components/dialog',
		Dropdown: '/components/dropdown',
		Error: '/components/error',
		Form: '/components/form',
		Grid: '/components/grid',
		Iconography: '/components/iconography',
		Loader: '/components/loader',
		'Multi-Select': '/components/multi-select',
		Panel: '/components/panel',
		'Progress Bar': '/components/progress-bar',
		'Radio Button': '/components/radio-button',
		Select: '/components/select',
		'Spin Button': '/components/spin-button',
		Stepper: '/components/stepper',
		Switch: '/components/switch',
		Toast: '/components/toast',
		Tooltip: '/components/tooltip'
	},
	tools: '/tools',
	toolPages: {
		Helpers: '/tools/helpers'
	}
};