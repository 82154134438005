import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Checkbox from '../../../common/Checkbox';
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = (
    <fieldset>
      <Checkbox
        id="checkbox1"
        label="Option one"
        name="group_name"
        onChange={e => console.log(e.target.value)}
        value="1"
      />

      <Checkbox
        id="checkbox2"
        label="Option two"
        name="group_name"
        onChange={e => console.log(e.target.value)}
        value="2"
      />

      <Checkbox
        disabled
        id="checkbox3"
        label="Option three"
        name="group_name"
        onChange={e => console.log(e.target.value)}
        value="3"
      />
    </fieldset>
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        {/* TODO: Look at this instead of jsxToString (https://github.com/algolia/react-element-to-jsx-string) */}

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
