/**
 * Renders a code snippet
 */

import React from 'react';

// Translation
import translate from '../translate/Translate';

// Dependencies
import Prism from 'prismjs';

// Components
import Toggle from './Toggle';

const Snippet = class Snippet extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      expand: false,
      react: true
    }
  }

  render() {
    const { code, name, react, translation } = this.props;
    let output;

    if(this.state.react && react) {
      output = react;
    } else {
      output = code;
    }

    // Remove array properties which mess up syntax highlighting
    output = output.replace(new RegExp(/ *={\[[^\]]*]}/, 'g'), '={array}');

    // Remove unnecessary quotes from code snippet
    output = output.replace(new RegExp('=""', 'g'), '');
    
    // Add line breaks to code snippet
    output = output.replace(new RegExp('><', 'g'), '>\n<');
    output = output.replace(new RegExp('>', 'g'), '>\n');

    let snippet = window.prettier.format(output, {
      parser: 'html',
      plugins: window.prettierPlugins,
      htmlWhitespaceSensitivity: 'ignore',
      printWidth: 60
    });

    // Remove quotes around braced attributes and unnecessary key attributes
    snippet = snippet.replace(new RegExp(/"{(.*?)}"/, 'g'), '{$1}');
    snippet = snippet.replace(new RegExp(/key="(.*?)"/, 'g'), '');

    // Handle component names that correspond with HTML elements
    snippet = snippet.replace(new RegExp('<select', 'g'), '<Select');

    return (
      <div className={`snippet ${!this.state.expand ? 'snippet--collapse' : ''}`}>
        <div className="snippet__header">
          {this.state.expand &&
            <Toggle
              id={name}
              label="HTML"
              before="React"
              onChange={() => this.setState({ react: !this.state.react })}
              defaultChecked={!this.state.react}
            />
          }

          <button
            className="snippet__expand btn--link"
            onClick={() => this.setState({ expand: !this.state.expand })}
          >
            {this.state.expand && translation.hide_code}
            {!this.state.expand && translation.show_code}
          </button>
        </div>

        <code className="snippet__code" ref={this.snippet}>
          <pre dangerouslySetInnerHTML={{ __html: Prism.highlight(snippet, Prism.languages.html, 'html') }} />
        </code>
      </div>
    )
  }
}

export default translate('Snippet')(Snippet);