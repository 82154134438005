import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import RadioGroup from '../../../common/RadioGroup';
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = (
    <RadioGroup
      id="rboxExample"
      legend="Group of radio buttons"
      name="group_name"
      onChange={e => console.log(e.target.value)}
      options={[
        {
          label: "Option one",
          value: "1"
        }, {
          label: "Option two",
          value: "2"
        }, {
          label: "Option three",
          value: "3",
          disabled: true
        }
      ]}
      value="1"
    />
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
