import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = [
    <div className="panel" key="1">
      <h1 className="panel__title">Panel Title</h1>

      <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Vestibulum id ligula porta felis euismod semper. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
    </div>
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
