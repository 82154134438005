import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../../translate/Translate';

const Components = observer(class Components extends React.Component {
  render() {
    const { translation } = this.props;

    return (
      <main className="page wrap">
				<h1>{translation.heading}</h1>
      </main>
    )
  }
})

export default translate('Components')(Components);
