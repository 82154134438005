import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Snippet from '../../../common/Snippet';
import SpinButton from '../../../common/SpinButton';

const Primary = class Primary extends React.Component {
  example = (
    <SpinButton
      defaultValue={2}
      id="test"
      helper="Between 1 and 6"
      label="Available slots"
      max={6}
      min={1}
      onChange={e => console.log(e)}
    />
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
