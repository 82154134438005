import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import AutoGrow from '../../../common/AutoGrow';
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = (
    <AutoGrow
      defaultValue="This is such a cute textarea!"
      id="autogrow-example"
      onInput={e => console.log(e.target.value)}
      onSubmit={e => console.log(`Submitting: ${e.target.value}`)}
      placeholder="Type a whole bunch of text…"
      submitOnEnter
    />
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        {/* TODO: Look at this instead of jsxToString (https://github.com/algolia/react-element-to-jsx-string) */}

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
          name="primary"
          react={jsxToString(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
