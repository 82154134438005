import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Renders a component with tab list and tab panels
 * 
 * @param {object} props
 * @property {Function} [onTabChange]
 * @property {Array} tabContents - Array of the contents of the tab panels
 * @property {Array} tabNames - Array of the names to be shown on the tab list
 * @returns {Function}
 */
const Tabs = props => {
  // Get values from props
  const { onTabChange, tabContents, tabs } = props;

  // Set up state
  const [activeTab, setActiveTab] = React.useState(props.activeTab);

  /**
   * React to tab changes
   * 
   * @function handleTabChange
   * @param {number} i
   */
  const handleTabChange = i => {
    if (onTabChange) {
      onTabChange(i);
    }

    setActiveTab(i);
  }

  return (
    <div className="tab-wrap">
      <ul className="tab-list">
        {tabs.map( (tab, i) =>
          <li key={i}>
            {!tab.url &&
              <button
                className={`tab ${i === activeTab ? 'tab--active' : ''}`}
                onClick={() => handleTabChange(i)}
              >
                {tab.title}
              </button>
            }

            {tab.url &&
              <Link
                className={`tab ${i === activeTab ? 'tab--active' : ''}`}
                to={tab.url}
                title={tab.title}
                onClick={() => handleTabChange(i)}
              >
                {tab.title}
              </Link>
            }
          </li>
        )}
      </ul>

      {tabContents && tabContents.map((tabContent, i) => {
        if (i === activeTab) {
          return (
            <div className="tab-panel" key={i}>
              {tabContent}
            </div>
          )
        }

        return null;
      })}
    </div>
  )
}

export default Tabs;