import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Action = class Action extends React.Component {
  example = [
    <div className="panel panel--md" key="1">
      <h1 className="panel__title">Medium Panel</h1>

      <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Vestibulum id ligula porta felis euismod semper. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>

      <div className="panel__action">
        <button className="btn btn--outline">Cancel</button>
        <button className="btn">Submit</button>
      </div>
    </div>,
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.action}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="sizing"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Action;
