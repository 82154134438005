/**
 * Renders a toggle checkbox
 */

import React from 'react';

const Toggle = class Toggle extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      jsx: false
    }
  }

  render() {
    const { before, defaultChecked, id, label, onChange } = this.props;
    return (
      <div className="toggle">
        {before &&
          <label
            className="toggle__before"
            htmlFor={id}
            aria-hidden="true"
            role="presentation"
          >
            {before}
          </label>
        }

        <input
          type="checkbox"
          className="toggle__inp"
          id={id}
          onChange={onChange}
          defaultChecked={defaultChecked}
        />

        <label
          className="toggle__lbl"
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    )
  }
}

Toggle.displayName = 'Toggle';

export default Toggle;
