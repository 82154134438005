import React from 'react';

// Helpers
import { focusable } from '../../helpers';

// Components
import Countdown from './Countdown';
import Select from './Select';

/**
 * Renders a pop-up dialog component
 * 
 * @param {object} props
 * @property {object} content
 * @property {Function} [onCancel]
 * @property {Function} [onChange] - Optional callback for options change event
 * @property {Function} [onConfirm]
 * @property {Function} [onTimeout]
 * @property {Array} [options]
 * @property {number} [timeout]
 * @property {string} [type]
 * @returns {Function}
 */
const Dialog = props => {
  // Get values from props
  const {
    content,
    onCancel,
    onChange,
    onConfirm,
    onTimeout,
    options,
    timeout,
    type
  } = props;

  // Set up state
  const [focusIndex, setFocusIndex] = React.useState(0);

  // Create refs
  const dialogRoot = React.useRef(null);

  // Create an array to hold focusable child elements.
  let _focusableElements = [];

  // Main useEffect hook
  React.useEffect(() => {
    // Add focusable child elements to
    // the array and focus the first one.
    if (!_focusableElements.length) {
      _focusableElements = dialogRoot.current.querySelectorAll(focusable);
    }

    _focusableElements[focusIndex].focus();
  }, [focusIndex])

  /**
   * Focus appropriate elements
   * 
   * @function focusElement
   * @param {number} index
   */
  const focusElement = index => {
    setFocusIndex(index);
  }

  /**
   * Handle keyboard interaction
   * 
   * @function handleKeyDown
   * @param {event} e
   */
  const handleKeyDown = e => {
    const first = 0;
    const last = _focusableElements.length - 1;

    const ESCAPE = "Escape";
    const TAB = "Tab";
    const SHIFT = e.shiftKey;

    // Tab Key behavior
    if (e.key === TAB && !SHIFT) {
      e.preventDefault();
      
      if (focusIndex < last) {
        focusElement(focusIndex + 1);
      } else {
        focusElement(first);
      }
    }

    // Shift + Tab Key behavior
    if (e.key === TAB && SHIFT) {
      e.preventDefault();
      if (focusIndex > 0) {
        focusElement(focusIndex - 1);
      } else {
        focusElement(last);
      }
    }

    // Escape Key behavior
    if (e.key === ESCAPE) {
      handleCancel(e);
    }
  }

  /**
   * Handle dialog cancellation
   * 
   * @function handleCancel
   */
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }

    handleClose();
  }

  /**
   * Handle dialog confirmation
   * 
   * @function handleConfirm
   */
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }

    handleClose();
  }

  /**
   * Handle dialog close
   * 
   * @function handleClose
   */
  const handleClose = () => {
    const { onClose, store } = props;
    const { AppStore } = store;

    if (onClose) {
      onClose();
    }

    AppStore.closeDialog();
  }

  /**
   * Convey the urgency of the message to assistive technology
   * 
   * @function renderRole
   * @param {string} type
   * @returns {string}
   */
  const renderRole = type => {
    let role;

    switch (type) {
      case 'confirm':
      case 'alert':
      case 'error':
        role = 'alertdialog';
        break;

      case 'wide':
      case 'html':
      case 'info':
        role = 'dialog';
        break;

      default:
        role = 'dialog';
    }

    return role;
  }

  return (
    <div
      aria-describedby="dialog-description"
      aria-labelledby="dialog-title"
      aria-modal="true"
      className={`dialog${type ? ` dialog--${type}` : ''}`}
      id="dialog-root"
      onKeyDown={handleKeyDown}
      ref={dialogRoot}
      role={renderRole(type)}
    >
      <div
        className={`dialog__content${type ? ` dialog__content--${type}` : ""
        }`}
      >
        <div className="dialog__head">
          <h2 id="dialog-title" className="dialog__title">
            {content.heading}
          </h2>

          {timeout && (
            <Countdown
              {...props}
              className="dialog__countdown"
              onTimeout={onTimeout}
              timeout={timeout}
            />
          )}
        </div>

        <p id="dialog-description">{content.body}</p>

        {options &&
          <Select
            className="dialog__select"
            onChange={onChange}
            options={options}
          />
        }

        <div className="dialog__action">
          {type === 'confirm' &&
            <button
              className="btn btn--outline"
              onClick={handleCancel}
            >
              {content.cancel}
            </button>
          }

          <button
            className="btn"
            onClick={handleConfirm}
          >
            {content.button}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Dialog;