/**
 * Renders the Component view shell and determines which component to show
 */

import React from 'react';

// Translation
import translate from '../../translate/Translate';

// Helpers
import { getUrlSegment } from '../../../helpers';

// Components
import Tabs from '../../common/Tabs';
import AutoGrow from './autogrow/Index';
import Button from './button/Index';
import Checkbox from './checkbox/Index';
import Dialog from './dialog/Index';
import Dropdown from './dropdown/Index';
import Error from './error/Index';
import Form from './form/Index';
import Grid from './grid/Index';
import Iconography from './iconography/Index';
import Loader from './loader/Index';
import MultiSelect from './multiSelect/Index';
import Panel from './panel/Index';
import ProgressBar from './progressBar/Index';
import RadioGroup from './radioGroup/Index';
import Select from './select/Index';
import SpinButton from './spinButton/Index';
import Stepper from './stepper/Index';
import Switch from './switch/Index';
import Toast from './toast/Index';
import Tooltip from './tooltip/Index';

const Component = class Component extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      view: ''
    };
  }

  componentDidMount() {
    this.checkView();
  }

  componentDidUpdate() {
    this.checkView();
  }

  checkView() {
    // Determine which component to show based on the URL
    let view = '';

    switch(getUrlSegment(2)) {
      case 'autogrow':
        view = 'AutoGrow';
        break;

      case 'button':
        view = 'Button';
        break;

      case 'checkbox':
        view = 'Checkbox';
        break;

      case 'dialog':
        view = 'Dialog';
        break;

      case 'dropdown':
        view = 'Dropdown';
        break;

      case 'error':
        view = 'Error';
        break;

      case 'form':
        view = 'Form';
        break;

      case 'grid':
        view = 'Grid';
        break;

      case 'iconography':
        view = 'Iconography';
        break;

      case 'loader':
        view = 'Loader';
        break;

      case 'multi-select':
        view = 'MultiSelect';
        break;

      case 'panel':
        view = 'Panel';
        break;

      case 'progress-bar':
        view = 'ProgressBar';
        break;

      case 'radio-button':
        view = 'RadioGroup';
        break;

      case 'select':
        view = 'Select';
        break;

      case 'spin-button':
        view = 'SpinButton';
        break;

      case 'stepper':
        view = 'Stepper';
        break;

      case 'switch':
        view = 'Switch';
        break;

      case 'toast':
        view = 'Toast';
        break;

      case 'tooltip':
        view = 'Tooltip';
        break;

      default:
        view = '';
    }

    if(view !== this.state.view) {
      this.setState({
        view
      });
    }
  }

  render() {
    let { translation } = this.props;

    if(this.state.view) {
      translation = this.props.store.AppStore.translation[this.state.view];
    }

    const Components = {
      AutoGrow,
      Button,
      Checkbox,
      Dialog,
      Dropdown,
      Error,
      Form,
      Grid,
      Iconography,
      Loader,
      MultiSelect,
      Panel,
      ProgressBar,
      RadioGroup,
      Select,
      SpinButton,
      Stepper,
      Switch,
      Toast,
      Tooltip
    };

    const View = Components[this.state.view] || null;

    const tabs = [
      {
        title: 'Examples',
        url: `/components/${getUrlSegment(2)}`
      }, {
        title: 'Usage',
        url: `/components/${getUrlSegment(2)}/usage`
      }
    ]

    let activeTab = 'examples';
    let activeTabIndex = 0;

    if(getUrlSegment(3) === 'usage') {
      activeTab = 'usage';
      activeTabIndex = 1;
    }

    return (
      <main className="page">
				<header className="page__header banner">
					<div className="wrap">
						<h1 className="page__title">{translation.heading}</h1>
					</div>

          <nav className="page__nav">
            <Tabs
              {...this.props}
              tabs={tabs}
              activeTab={activeTabIndex}
            />
          </nav>
				</header>

        {this.state.view &&
          <View
            {...this.props}
            activeTab={activeTab}
          />
        }
      </main>
    )
  }
}

export default translate('Component')(Component);
