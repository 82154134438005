import React from 'react';

// Icons
import * as icons from '../ui/Icons';

/**
 * Renders a toast message component
 * 
 * @param {object} props
 * @property {string} content
 * @property {Function} [onDismiss]
 * @property {number} [timeout]
 * @returns {Function}
 */
const Toast = props => {
  // Get values from props
  const {
    content,
    onDismiss,
    timeout,
    type
  } = props;

  // Set up state
  const [exiting, setExiting] = React.useState(false);

  // Create refs
  const toastEl = React.createRef();

  // Set up timeout handler
  let toastTimeout = null;

  // Main useEffect hook
  React.useEffect(() => {
    startTimeout();

    toastEl?.current?.addEventListener('mouseenter', cancelTimeout, false);
    toastEl?.current?.addEventListener('mouseleave', startTimeout, false);

    return () => {
      toastEl?.current?.removeEventListener('mouseenter', cancelTimeout, false);
      toastEl?.current?.removeEventListener('mouseleave', startTimeout, false);
    }
  }, [exiting]);

  /**
   * Remove the toast message after the set amount of time
   * 
   * @function startTimeout
   */
  const startTimeout = () => {
    if (!timeout) {
      return;
    }

    toastTimeout = setTimeout(() => {
      handleDismiss();
    }, timeout);
  }

  /**
   * Cancel the timeout
   * 
   * @function cancelTimeout
   */
  const cancelTimeout = () => {
    clearTimeout(toastTimeout);
  }

  /**
   * Dismiss the toast message
   * 
   * @function handleDismiss
   */
  const handleDismiss = () => {
    setExiting(true);

    clearTimeout(toastTimeout);

    // Wait for the animation to finish
    // and then remove the toast.
    setTimeout(() => {
      if (onDismiss) {
        onDismiss();
      }
    }, 250);
  }

  /**
   * Render the appropriate icon
   * 
   * @function renderIcon
   * @param {string} type
   * @returns {Function}
   */
  const renderIcon = type => {
    let icon;

    switch (type) {
      case 'positive':
        icon = <icons.checkCircle />;
        break;

      case 'warn':
        icon = <icons.warningCircle />;
        break;

      case 'negative':
        icon = <icons.minusCircle />;
        break;

      default:
        icon = <icons.infoCircle />;
    }

    return icon;
  }

  return (
    <aside
      className={`toast${
        type ? ` toast--${type}` : ''
      }${
        exiting ? ' toast--exiting' : ''
      }`}
      ref={toastEl}
    >
      <i className="toast__icon">
        {renderIcon(type)}
      </i>

      <span className="toast__content">
        {content}
      </span>

      <button
        className="toast__close"
        onClick={handleDismiss}
      >
        <icons.times />
        <span className="meta">Dismiss</span>
      </button>
    </aside>
  )
}

Toast.displayName = 'Toast';

export default Toast;