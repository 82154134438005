import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Snippet from '../../../common/Snippet';
import Tooltip from '../../../common/Tooltip';

const Variations = class Variations extends React.Component {
  render() {
    const { translation } = this.props;

    const example = (
      <div>
        Brief

        <Tooltip
          brief
          content="Brief tooltip"
          id="example-tooltip-brief"
        />

        Down

        <Tooltip
          content="Example down tooltip text. Lorem ipsum dolor sit amet."
          down
          id="example-tooltip-down"
        />

        Left

        <Tooltip
          content="Example down tooltip text. Lorem ipsum dolor sit amet."
          id="example-tooltip-left"
          left
        />

        Right

        <Tooltip
          content="Example down tooltip text. Lorem ipsum dolor sit amet."
          id="example-tooltip-right"
          right
        />

        Brief down

        <Tooltip
          brief
          content="Brief down tooltip"
          down
          id="example-tooltip-brief-down"
        />

        Brief left

        <Tooltip
          brief
          content="Brief left tooltip"
          id="example-tooltip-brief-left"
          left
        />

        Brief right

        <Tooltip
          brief
          content="Brief right tooltip"
          id="example-tooltip-brief-right"
          right
        />
      </div>
    );

    return (
      <section className="page__section">
        <h3>{translation.examples.variations}</h3>

        <article
          className="example"
        >
          {example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(example)}
          name="primary"
          react={jsxToString(example)}
        />
      </section>
    )
  }
}

export default Variations;
