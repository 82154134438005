import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Select from '../../../common/Select';
import Snippet from '../../../common/Snippet';

const options = [{
  label: 'Dog',
  value: 'dog'
}, {
  label: 'Cat',
  value: 'cat'
}, {
  label: 'Ocean Sunfish',
  value: 'ocean-sunfish'
}];

const Primary = class Primary extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      value1: null,
      value2: null,
      value3: null,
      value4: null
    };
  }

  render() {
    const { translation } = this.props;

    const example = (
      <fieldset className="form--inline">
        <Select
          id="best-animal-1"
          label="Best animal"
          onChange={e => console.log(e.target.value)}
          options={options}
          placeholder="Choose the best animal…"
        />
  
        <Select
          defaultValue="dog"
          id="best-animal-2"
          label="Best animal"
          onChange={e => console.log(e.target.value)}
          options={options}
          placeholder="Choose the best animal…"
          value={this.state.value2}
        />
  
        <Select
          disabled
          hideLabel
          id="best-animal-3"
          label="Best animal"
          onChange={e => console.log(e.target.value)}
          options={options}
          placeholder="Choose the best animal…"
          value={this.state.value3}
        />
  
        <Select
          hideLabel
          id="best-animal-4"
          label="Best animal"
          multiple
          onChange={e => console.log(e.target.value)}
          options={options}
          value={this.state.value4}
        />
      </fieldset>
    );

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(example)}
          name="primary"
          react={jsxToString(example)}
        />
      </section>
    )
  }
}

export default Primary;
