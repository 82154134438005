import React from 'react';

/**
 * Renders a steps list component
 * 
 * @param {object} props
 * @property {string} [className]
 * @property {boolean} [clickable]
 * @property {number} currentStep
 * @property {boolean} hideLabels
 * @property {Function} onClick
 * @property {Array} steps
 * @returns {Function}
 */
const Stepper = props => {
  // Get values from props
  const {
    className,
    clickable,
    currentStep,
    hideLabels,
    onClick,
    steps
  } = props;

  /**
   * Render an individual step as a button or
   * a span depending on whether or not onClick exists
   * 
   * @function renderStep
   * @param {object} step
   * @param {number} i
   * @returns {Function}
   */
  const renderStep = (step, i) => {
    let className = `stepper__point`;

    currentStep > i && (className += ' stepper__point--complete');
    currentStep === i && (className += ' stepper__point--active');
    hideLabels && (className += ' stepper__point--hide-label');

    if (clickable && onClick) {
      return (
        <button
          className={className}
          onClick={() => onClick(i)}
          type="button"
        >
          <span className="stepper__lbl">
            {step}
          </span>
        </button>
      )
    } else {
      return (
        <span className={className}>
          <span className="stepper__lbl">
            {step}
          </span>
        </span>
      )
    }
  }

  return (
    <ol
      className={`stepper${
        className ? ` ${className}` : ''
      }`}
    >
      {steps && steps.length > 0 &&
        steps.map((step, i) =>
          <li key={i}>
            {renderStep(step, i)}
          </li>
        )
      }
    </ol>
  )
}

Stepper.displayName = 'Stepper';

export default Stepper;