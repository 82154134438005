import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Helpers
import { checkValidity } from '../../../../helpers';

// Components
import Snippet from '../../../common/Snippet';

const Inline = class Inline extends React.Component {
  example = [
    <form
      className="form form--inline"
      onSubmit={(e) => checkValidity(e, null)}
      noValidate
      key="1"
    >
      <fieldset>
        <label className="form__lbl required" htmlFor="form-text1">Input label</label>

        <input
          id="form-text1"
          type="text"
          placeholder="Optional placeholder text"
          required
          aria-required="true"
          data-errormsg="Error message with instructions to correct"
        />
      </fieldset>

      <button className="btn" type="submit">Submit</button>
    </form>
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.inline}</h3>

        <article
          className="example example--wrap"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Inline;
