/**
 * Renders the app header
 */

import React from 'react';
import { Link } from 'react-router-dom';

// Import components
import Nav from './Nav';

const Header = class Header extends React.Component {
  render() {
    const { AppStore } = this.props.store;

    return (
      <header id="header" className="app-head">
        <Link
          className="brand"
          to="/"
          title="Didactic Design"
        >
          <h1 className="meta">
            Didactive Design System
          </h1>

          <img
            className="brand__logo"
            src="/static/img/logo.svg"
            height="25"
            alt="The Didactic Design logo"
            title="The Didactic Design logo"
          />
        </Link>

        <button
          className="btn--reset app-head__menu"
          onClick={AppStore.toggleNav}
        >
          <span className="meta">Menu</span>
        </button>

        <Nav
          {...this.props}
        />
      </header>
    )
  }
}

export default Header;
