import React from 'react';

/**
 * Renders a switch checkbox component
 * 
 * @param {object} props
 * @property {string} [className]
 * @property {boolean} [defaultChecked]
 * @property {boolean} [disabled]
 * @property {boolean} [fullWidth]
 * @property {string} id
 * @property {string} label
 * @property {string} labelOff
 * @property {string} labelOn
 * @property {string} name
 * @property {Function} onChange
 * @property {string} value
 * @returns {Function}
 */
const Switch = props => {
  const {
    className,
    defaultChecked,
    disabled,
    fullWidth,
    id,
    label,
    labelOff,
    labelOn,
    name,
    onChange,
    required,
    value
  } = props;

  return (
    <div
      className={`switch${
        fullWidth ? ' switch--full' : ''
      }${
        className ? ` ${className}` : ''}`
      }
    >
      {label &&
        <label
          className="switch__lbl"
          htmlFor={id}
        >
          {label}
        </label>
      }

      <div>
        <input
          aria-required={required}
          defaultChecked={defaultChecked}
          className="switch__input"
          disabled={disabled}
          id={id}
          onChange={e => onChange(e)}
          name={name}
          required={required}
          type="checkbox"
          value={value || true}
        />

        <label 
          className="switch__off"
          htmlFor={id}
        >
          {labelOff}
        </label>

        <label 
          className="switch__on"
          htmlFor={id}
        >
          {labelOn}
        </label>
      </div>
    </div>
  )
}

export default Switch;