import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = [
    <div className="progress-bar">
      <progress
        className="progress-bar__bar"
        key="1"
        max="100"
        min="0"
        value="75"
        aria-labelledby="progress-value"
      />

      <output
        className="progress-bar__value"
        id="progress-value"
      >
        75 of 100 uploaded
      </output>
    </div>
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
