import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Positive = class Positive extends React.Component {
  example = [
    <button className="btn btn--positive" key="1">
      Button
    </button>,

    <button className="btn btn--positive" key="2">
      <svg aria-hidden="true" className="icon icon-plus" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3125 1H6.6875V6.6875H1V9.3125H6.6875V15H9.3125V9.3125H15V6.6875H9.3125V1Z"/></svg>

      Button
    </button>,

    <button className="btn btn--outline btn--positive" key="3">
      Button
    </button>,

    <button className="btn btn--outline btn--positive" key="4">
      <svg aria-hidden="true" className="icon icon-plus" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3125 1H6.6875V6.6875H1V9.3125H6.6875V15H9.3125V9.3125H15V6.6875H9.3125V1Z"/></svg>

      Button
    </button>
  ]

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.positive}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Positive;
