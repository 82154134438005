import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Routes
import { routes } from '../routes';

// Import common components
import Dialog from './common/Dialog';

// Import UI components
import Header from './ui/Header';

// Import views
import Home from './views/Home';
import Components from './views/components/Index';
import Component from './views/components/Component';
import Helpers from './views/tools/Helpers';

class App extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      render: false
    };

    // Load translation
    const { AppStore } = props.store;

    if(!AppStore.translation) {
      const callback = () => {
        this.setState({
          render: true
        });
      }

      AppStore.getTranslation(null, callback);
    }
  }

  render() {
    const { AppStore } = this.props.store;

    if(this.state.render) {
      return (
        <BrowserRouter>
          <div
            className={
              `viewport${
                AppStore.navOpen ? ' nav-open' : ''
              }`
            }
          >
            <Header {...this.props} />

            <Route render={({location}) => (
              <Switch location={location}>
                <Route exact path={routes.index} render={(routing) => (
                  <Home
                    {...routing}
                    {...this.props} 
                  />
                )}/>

                <Route exact path={routes.components} render={(routing) => (
                  <Components
                    {...routing}
                    {...this.props} 
                  />
                )}/>

                <Route path={`${routes.components}/:componentId`} render={(routing) => (
                  <Component
                    {...routing}
                    {...this.props} 
                  />
                )}/>

                <Route exact path={routes.toolPages.Helpers} render={(routing) => (
                  <Helpers
                    {...routing}
                    {...this.props} 
                  />
                )}/>
              </Switch>
            )} />

            {/*
              Note:
              Starter kit has an example dialog set in the AppStore.js file. Set dialog to false and remove the dialogContent object’s properties to remove it.
            */}
            {AppStore.dialog &&
              <Dialog {...this.props} />
            }

            {AppStore.loading &&
              <aside className="loader">
                <div className="loader__msg">
                  <span className="meta">Loading…</span>
                </div>
              </aside>
            }
          </div>
        </BrowserRouter>
      )
    }

    return null;
  }
}

export default observer(App);
