import React from 'react';

// Icons
import * as icons from '../ui/Icons';
 
/**
 * Renders a numeric spin button component
 * 
 * @param {object} props
 * @property {string} className
 * @property {number} defaultValue
 * @property {boolean} [disabled]
 * @property {string} [errorMsg]
 * @property {string} [helper] A hint or description
 * @property {boolean} [hideLabel]
 * @property {string} id
 * @property {string} label
 * @property {string} [layout]
 * @property {number} max The maximum possible value
 * @property {number} min The minimum possible value
 * @property {Function} onChange
 * @property {boolean} [required]
 * @returns {Function}
 */
const SpinButton = props => {
  // Get values from props
  const {
    className,
    defaultValue,
    disabled,
    errorMsg,
    helper,
    hideLabel,
    id,
    label,
    layout,
    max,
    min,
    onChange,
    required
  } = props;

  // Set up state
  const [value, setValue] = React.useState(defaultValue || 0);

  // Create refs
  const inputEl = React.createRef();

  // Main useEffect loop
  React.useEffect(() => {
    if (value !== defaultValue) {
      handleChange();
    }
  }, [value]);
 
  /**
   * Increment and decrement with up and down keys.
   * 
   * @function handleKeyDown
   * @param {event} e 
   */
  const handleKeyDown = e => {
    const code = e.code.toLowerCase();
 
    if (code === 'arrowup') {
      handleIncrement(1);
    } else if (code === 'arrowdown') {
      handleIncrement(-1);
    }
  }
 
  /**
   * Handle increment/decrement button click.
   * 
   * @function handleIncrement
   * @param {number} amount 
   */
  const handleIncrement = amount => {
    let newValue = value + (+amount);
 
    if (newValue < min) {
      newValue = min;
    } else if (value > max) {
      newValue = max;
    }
 
    handleValidity(false);

    setValue(+newValue);
  }
 
  /**
   * Handle typing in the input
   * 
   * @function handleInput
   * @param {string} newValue
   */
  const handleInput = newValue => {

    if (Number.isNaN(+newValue)) {
      return;
    }
 
    if (+newValue < min || +newValue > max) {
      this.handleValidity(true);
    } else {
      this.handleValidity(false);
    }
 
    setValue(+newValue);
  }
 
  /**
   * Set input validity.
   * 
   * @function handleValidity
   * @param {boolean} invalid
   */
  const handleValidity = invalid => {

    if (invalid) {
      inputEl.current.setAttribute('aria-invalid', true);
      inputEl.current.setCustomValidity('Invalid value');
      inputEl.current.reportValidity();
    } else {
      inputEl.current.removeAttribute('aria-invalid');
      inputEl.current.setCustomValidity('');
      inputEl.current.reportValidity();
    }
  }
 
  /**
   * Handle onChange property
   * 
   * @function handleChange
   */
  const handleChange = () => {
    if (onChange) {
      onChange(value);
    }
  }
 
  return (
    <div className={`spin-button${className ? ` ${className}` : ''}${layout ? ` spin-button--${layout}` : ''}`}>
      <div className="spin-button__head">
        <label
          className={`spin-button__lbl form__lbl${
            hideLabel ? ' meta' : ''
          }`}
          htmlFor={id}
        >
          {label}
        </label>

        {helper &&
            <span
              className="spin-button__hint form__hint"
              id={`${id}-helper`}
            >
              {helper}
            </span>
        }
      </div>

      <div className="spin-button__wrap">
        <input
          aria-describedby={`${id}-helper`}
          aria-valuemax={max}
          aria-valuemin={min}
          aria-valuenow={value}
          className={`spin-button__input${
            max <= 99 ? ' input--xs' : ''
          }${
            max > 99 & max <= 999 ? ' input--sm' : ''
          }${
            max > 999 & max <= 9999 ? ' input--md' : ''
          }`}
          data-errormsg={errorMsg || null}
          disabled={disabled}
          id={id}
          inputMode="numeric"
          onKeyDown={e => handleKeyDown(e)}
          onChange={e => handleInput(e.target.value)}
          pattern="[-+,0-9]*"
          ref={inputEl}
          required={required}
          role="spinbutton"
          type="text"
          value={value}
        />

        <button
          aria-label={`decrease ${label}`}
          aria-live="polite"
          className="spin-button__control spin-button__down btn--reset btn--ghost"
          disabled={value <= min || disabled}
          onClick={() => handleIncrement(-1)}
          tabIndex="-1"
          type="button"
        >
          <icons.minus />
        </button>

        <button
          aria-label={`increase ${label}`}
          aria-live="polite"
          className="spin-button__control spin-button__up btn--reset btn--ghost"
          disabled={value >= max || disabled}
          onClick={() => handleIncrement(1)}
          tabIndex="-1"
          type="button"
        >
          <icons.plus />
        </button>
      </div>
    </div>
  )
}
 
export default SpinButton;