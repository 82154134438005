import React from 'react';

// Components
import Checkbox from './Checkbox';

/**
 * Renders a list box of checkboxes
 * 
 * @param {object} props
 * @property {string} [className]
 * @property {string} errorMsg
 * @property {boolean} [disabled]
 * @property {string} [helper]
 * @property {boolean} [hideLabel]
 * @property {string} id
 * @property {string} label
 * @property {Function} onChange
 * @property {Array} options
 * @property {boolean} [required]
 * @property {Array} [value]
 * @returns {Function}
 */
const MultiSelect = props => {
  // Get values from props
  const {
    className,
    errorMsg,
    disabled,
    helper,
    hideLabel,
    id,
    label,
    onChange,
    options,
    required,
    value
  } = props;

  // Set up state
  const [scrollable, setScrollable] = React.useState(false);
  const [selected, setSelected] = React.useState(value || []);

  // Create refs
  const listbox = React.useRef(null);

  /**
   * Handle any preset values
   * 
   * @function handleValue
   */
  const handleValue = () => {
    if (Array.isArray(value)) {
      setSelected(value);
    }
  }

  /**
   * Check if listbox is scrollable
   * (Timeout is necessary because the function may fire before render is completef)
   * 
   * @function handleOverflow
   */
  const handleOverflow = () => {
    setTimeout(() => {
      const el = listbox.current;
      let isScrollable = false;

      if (el && el.scrollHeight > el.offsetHeight) {
        isScrollable = true;
      }

      setScrollable(isScrollable);
    });
  }

  /**
   * Handle selection/deselection of an option
   * 
   * @function handleChange
   * @param {event} e
   * @param {object} option
   */
  const handleChange = (e, option) => {
    const { checked, value } = e.target;
    const index = selected.indexOf(value);
    const selectedOpts = [...selected];

    console.log(option)
    console.log(index);

    if (!checked && index > -1) {
      selectedOpts.splice(index, 1);

      if (option.children) {
        option.children.forEach(childOption => {
          const childIndex = selectedOpts.indexOf(childOption.value);

          if (childIndex > -1) {
            selectedOpts.splice(childIndex, 1);
          }
        });
      }
    } else if (index === -1) {
      selectedOpts.push(value);

      if (option.children) {
        option.children.forEach(childOption => {
          selectedOpts.push(childOption.value);
        });
      }
    }

    if (onChange) {
      onChange(selectedOpts);
    }

    setSelected(selectedOpts);
  }

  /**
   * Render a checkbox for an option
   * 
   * @function renderOption
   * @param {object} option
   * @param {number} index Used to define a unique ID attribute
   * @returns {Function}
   */
  const renderOption = (option, index) => {
    return (
      <Checkbox
        defaultChecked={selected.indexOf(option.value) > -1}
        disabled={disabled ? true : false}
        id={`${id}-option-${index}`}
        label={option.label}
        name={`group-${id}`}
        onChange={e => handleChange(e, option)}
        value={option.value}
      />
    )
  }

  return (
    <div
      className={`multi-select${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="multi-select__text">
        {label &&
          <span
            className={`${hideLabel ? 'meta' : 'multi-select__lbl form__lbl'}${
              required ? ' required' : ''
            }`}
            id={`${id}-label`}
          >
            {label}
          </span>
        }

        {helper &&
          <span
            className="multi-select__hint form__hint"
            id={`${id}-helper`}
          >
            {helper}
          </span>
        }
      </div>

      {/* Handle validation if required is true */}
      {required &&
        <input
          className="input--hidden"
          data-errormsg={errorMsg}
          defaultValue={selected.length > 0 ? selected.toString() : ''}
          required
          type="text"
        />
      }

      <ul
        aria-labelledby={`${id}-label`}
        className={`multi-select__options${
          scrollable ? ' multi-select__options--scrollable' : ''
        }`}
        ref={listbox}
        role="listbox"
      >
        {options.map((option, i) =>
          <li key={i}>
            {renderOption(option, i)}

            {option.children &&
              <ul
                aria-labelledby={`${id}-label`}
                className="multi-select__child-options"
              >
                {option.children.map((childOption, j) =>
                  <li key={j}>
                    {renderOption(childOption, `${i}-${j}`)}
                  </li>
                )}
              </ul>
            }
          </li>
        )}
      </ul>

      {handleOverflow()}
    </div>
  )
}

export default MultiSelect;