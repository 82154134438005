import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Icons
import * as icons from '../../../ui/Icons';

// Components
import Snippet from '../../../common/Snippet';

// Loop through all icons for example
const iconArr = [];

Object.keys(icons).forEach((icon, i) => {
  const IconEl = icons[icon];

  iconArr.push(<IconEl key={i} />);
});

const Primary = class Primary extends React.Component {
  example = (
    <div>
      {iconArr}
    </div>
  );

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          code={
            ReactDOMServer.renderToStaticMarkup(this.example)
              .replace(new RegExp('<div>', 'g'), '')
              .replace(new RegExp('</div>', 'g'), '')
          }
          name="primary"
          react={
            jsxToString(this.example)
              .replace(new RegExp('<div>', 'g'), '')
              .replace(new RegExp('</div>', 'g'), '')
              .replace(new RegExp('<', 'g'), '<icons.')
          }
        />
      </section>
    )
  }
}

export default Primary;
