/**
 * Renders the app header
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { action } from 'mobx';
import { observer } from 'mobx-react';

// Routes
import { routes } from '../../routes';

const Nav = observer(class Nav extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      expanded: [],
      pathname: window.location.pathname
    };
  }

  /**
   * Expand and collapse navigation sections
   * 
   * @function handleToggleSection
   * @param {string} section
   */
  handleToggleSection = section => {
    const expanded = [...this.state.expanded];
    const sectionIndex = expanded.indexOf(section);

    if(sectionIndex > -1) {
      expanded.splice(sectionIndex, 1);
    } else {
      expanded.push(section);
    }

    this.setState({ expanded });
  }

  render() {
    const { expanded, pathname } = this.state;
    const { AppStore } = this.props.store;

    return (
      <nav id="nav" className="nav app-nav">
        <h2 className="meta">Index</h2>

        <section className="nav__section">
          <h3>
            <button
              className="nav__heading"
              onClick={() => this.handleToggleSection('components')}
            >
              Components
            </button>
          </h3>

          <ul
            className={`nav__list${
              expanded.indexOf('components') > -1 ? ' nav__list--expanded' : ' nav__list--collapsed'
            }`}
          >
            {Object.keys(routes.componentsPages).map(
              (route, i) =>
              <li key={i}>
                <Link
                  className={
                    `nav__item${
                      pathname.includes(routes.componentsPages[route]) ? ' nav__item--active' : ''
                    }`
                  }
                  to={routes.componentsPages[route]}
                  onClick={action(() => {
                    AppStore.navOpen = false;

                    setTimeout(() => {
                      this.setState({
                        pathname: window.location.pathname
                      });
                    });                  
                  })}
                  title={route}
                >
                  {route}
                </Link>
              </li>
            )}
          </ul>
        </section>

        <section className="nav__section">
          <h3>
            <button
              className="nav__heading"
              onClick={() => this.handleToggleSection('tools')}
            >
              Tools
            </button>
          </h3>
          
          <ul
            className={`nav__list${
              expanded.indexOf('tools') > -1 ? ' nav__list--expanded' : ' nav__list--collapsed'
            }`}
          >
            {Object.keys(routes.toolPages).map(
              (route, i) =>
              <li key={i}>
                <Link
                  className={
                    `nav__item${
                      pathname.includes(routes.toolPages[route]) ? ' nav__item--active' : ''
                    }`
                  }
                  to={routes.toolPages[route]}
                  onClick={action(() => {
                    AppStore.navOpen = false;

                    setTimeout(() => {
                      this.setState({
                        pathname: window.location.pathname
                      });
                    });                  
                  })}
                  title={route}
                >
                  {route}
                </Link>
              </li>
            )}
          </ul>
        </section>
      </nav>
    )
  }
})

export default Nav;
