import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../../../translate/Translate';

// Components
import Primary from './Primary';
import Usage from './Usage';

const RadioGroup = observer(class RadioGroup extends React.Component {
  render() {
		const { activeTab } = this.props;

		if(activeTab === 'examples') {
			return (
				<div className="wrap">
					<Primary {...this.props} />
				</div>
			)
		} else if(activeTab === 'usage') {
			return (
				<div className="wrap">
					<Usage {...this.props} />
				</div>
			)
		}
  }
})

export default translate('RadioGroup')(RadioGroup);
