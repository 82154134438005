import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Components
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = [
    <button className="btn" key="1">
      Button
    </button>,

    <button className="btn" key="2">
      <svg aria-hidden="true" className="icon icon-check" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4.57558 11.1861L3.86217 11.8995L5.71833 13.7557L15.6178 3.85616L13.7617 2L5.71833 10.0433L5.71692 10.0447L2.2 6.52782L0.35 8.38782L3.86 11.8978L4.57558 11.1861Z"/></svg>

      Button
    </button>,

    <button className="btn" disabled key="3">
      Button
    </button>,

    <button className="btn" disabled key="4">
      <svg aria-hidden="true" className="icon icon-check" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4.57558 11.1861L3.86217 11.8995L5.71833 13.7557L15.6178 3.85616L13.7617 2L5.71833 10.0433L5.71692 10.0447L2.2 6.52782L0.35 8.38782L3.86 11.8978L4.57558 11.1861Z"/></svg>

      Button
    </button>
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
