import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../../../translate/Translate';

// Components
import Passive from './Passive';
import Confirmation from './Confirmation';
import Interactive from './Interactive';
import Usage from './Usage';
import Timeout from './Timeout';

const Dialog = observer(class Dialog extends React.Component {
  render() {
		const { activeTab } = this.props;

		if(activeTab === 'examples') {
			return (
				<div className="wrap">
					<Passive {...this.props} />
					<Confirmation {...this.props} />
					<Interactive {...this.props} />
					<Timeout {...this.props} />
				</div>
			)
		} else if(activeTab === 'usage') {
			return (
				<div className="wrap">
					<Usage {...this.props} />
				</div>
			)
		}
  }
})

export default translate('Dialog')(Dialog);
