import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Helpers
import { checkValidity } from '../../../../helpers';

// Components
import Checkbox from '../../../common/Checkbox';
import RadioGroup from '../../../common/RadioGroup';
import Select from '../../../common/Select';
import Snippet from '../../../common/Snippet';

const Primary = class Primary extends React.Component {
  example = [
    <form
      className="form"
      onSubmit={(e) => checkValidity(e, null)}
      noValidate
      key="1"
    >
      <fieldset>
        <ul className="form__fields">
          <li className="field field--half">
            <label className="form__lbl required" htmlFor="form-text1">Input label</label>

            <input
              id="form-text1"
              name="text_field1"
              type="text"
              placeholder="Optional placeholder text"
              required
              aria-required="true"
              data-errormsg="Error message with instructions to correct"
            />
          </li>
          <li className="field field--half">
            <label className="form__lbl required" htmlFor="form-text2">Input label</label>

            <input
              id="form-text2"
              name="text_field2"
              type="text"
              placeholder="Optional placeholder text"
              required
              aria-required="true"
              data-errormsg="Error message with instructions to correct"
            />
          </li>
          <li className="field">
            <Select
              id="example-select"
              label="Select label"
              onChange={value => console.log(value)}
              options={[{ label: 'Option one', value: '1'}, { label: 'Option two', value: '2'}]}
              placeholder="Choose an option…"
            />
          </li>
          <li className="field">
            <label className="form__lbl" htmlFor="form-text3">Input label</label>

            <input
              id="form-text3"
              name="text_field3"
              type="text"
              placeholder="Optional placeholder text"
            />
          </li>
          <li className="field">
            <label className="form__lbl required" htmlFor="form-textarea">Textarea label</label>

            <textarea
              id="form-textarea"
              name="textarea"
              placeholder="Optional placeholder text"
              required
              aria-required="true"
              data-errormsg="Error message with instructions to correct"
            />
          </li>
          <li className="field">
            <Checkbox
              id="example-checkbox"
              label="Checkbox label"
              name="group_name"
              onChange={checked => console.log(checked)}
              value="1"
            />
          </li>
          <li className="field">
            <RadioGroup
              id="example-radio"
              legend="Group of radio buttons"
              name="group_name"
              onChange={checked => console.log(checked)}
              options={[
                {
                  label: "Option one",
                  value: "1"
                }, {
                  label: "Option two",
                  value: "2",
                  defaultChecked: true
                }, {
                  label: "Option three",
                  value: "3",
                  disabled: true
                }
              ]}
            />
          </li>
        </ul>
      </fieldset>

      <button className="btn" type="submit">Submit</button>
    </form>
  ];

  render() {
    const { translation } = this.props;

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example example--wrap"
        >
          {this.example}
        </article>

        <Snippet
          {...this.props}
          name="primary"
          code={ReactDOMServer.renderToStaticMarkup(this.example)}
        />
      </section>
    )
  }
}

export default Primary;
