import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Dependencies
import jsxToString from 'jsx-to-string';

// Components
import Snippet from '../../../common/Snippet';
import Tooltip from '../../../common/Tooltip';

const Primary = class Primary extends React.Component {
  render() {
    const { translation } = this.props;

    const example = (
      <Tooltip
        content="Example tooltip text. Text should be contextual and non-essential."
        id="example-tooltip-1"
      />
    );

    return (
      <section className="page__section">
        <h3>{translation.examples.primary}</h3>

        <article
          className="example"
        >
          {example}
        </article>

        <Snippet
          {...this.props}
          code={ReactDOMServer.renderToStaticMarkup(example)}
          name="primary"
          react={jsxToString(example)}
        />
      </section>
    )
  }
}

export default Primary;
