import React from 'react';
import { observer } from 'mobx-react';

// Dependencies
import Markdown from 'markdown-to-jsx';
import Prism from 'prismjs';

// Translation
import translate from '../../translate/Translate';

const Helpers = observer(class Helpers extends React.Component {
	constructor() {
		super();

		// Set the initial state
		this.state = {
			scripts: null
		}
	}

	componentDidMount() {
		fetch('/static/js/helpers.js')
			.then(response => response.text())
			.then(scripts => this.setState({ scripts }));
	}

  render() {
		const { translation } = this.props;
		const { scripts } = this.state;

		return (
			<main className="page">
				<header className="page__header banner banner--navless">
					<div className="wrap">
						<h1 className="page__title">{translation.page_title}</h1>
					</div>
				</header>

        <div className="wrap">
					<section className="page__copy">
						<h2>{translation.heading}</h2>

						<Markdown options={{ forceBlock: true }}>
							{translation.intro}
						</Markdown>

						{scripts &&
							<code className="snippet__code">
								<pre
									dangerouslySetInnerHTML={{
										__html: Prism.highlight(scripts, Prism.languages.javascript, 'javascript')
									}}
								/>
							</code>
						}

						{/* {Object.keys(helpers).map(script =>
							<article className="helper">
								<h3 className="helper__title">{translation.scripts[script] || script}</h3>

								<code className="snippet__code">
									<pre
										dangerouslySetInnerHTML={{
											__html: helpers[script].toString()
										}}
									/>
								</code>
							</article>
						)} */}
					</section>
				</div>
      </main>
		)
  }
})

export default translate('Helpers')(Helpers);
