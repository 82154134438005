import React from "react";

/**
 * Renders a select component
 *
 * @param {object} props
 * @property {string} [className]
 * @property {boolean} [defaultValue]
 * @property {boolean} [disabled]
 * @property {boolean} [hideLabel]
 * @property {string} id
 * @property {string} label
 * @property {Function} onChange
 * @property {Array} options
 * @property {string} placeholder
 * @property {boolean} [required]
 * @returns {Function}
 */
const Select = props => {
  // Get values from props
  const {
    className,
    defaultValue,
    disabled,
    hideLabel,
    id,
    label,
    onChange,
    options,
    placeholder,
    required,
  } = props;
 
  return (
    <div className="select-wrap">
      <label className={hideLabel ? "meta" : "form__lbl select__lbl"} htmlFor={id}>
        {label}
      </label>

      <div className={`select${className ? ` ${className}` : ""}`}>
        <select
          aria-required={required}
          defaultValue={defaultValue || ""}
          disabled={disabled}
          id={id}
          onChange={e => onChange(e)}
          required={required}
        >
          <option value="">{placeholder || "Choose an option…"}</option>

          {options && options.length > 0 && options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
 
export default Select;
 