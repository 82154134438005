import React from 'react';

/**
 * Renders an auto-growing textarea.
 * 
 * @param {object} props
 * @property {string} [defaultValue]
 * @property {boolean} [disabled]
 * @property {string} errorMsg
 * @property {string} id
 * @property {Function} [onInput]
 * @property {Function} [onSubmit]
 * @property {string} placeholder
 * @property {boolean} [required]
 * @property {boolean} [submitOnEnter] - If true, shift+enter creates a new line. If false, enter creates a new line.
 * @returns {Function}
 */
const AutoGrow = props => {
  // Get values from props
  const {
    defaultValue,
    disabled,
    errorMsg,
    id,
    onInput,
    onSubmit,
    placeholder,
    required,
    submitOnEnter
  } = props;

  // Create refs
  const wrapper = React.useRef(null);

  /**
   * Use the input text to grow the textarea.
   * 
   * @function handleInput
   * @param {event} e
   */
  const handleInput = e => {
    wrapper.current.setAttribute('data-text', e.target.value || placeholder);

    if (onInput) {
      onInput(e);
    }
  }

  /**
   * Prevent adding a new line when just the Enter key is pressed.
   * This is because a lone enter key should fire submit and not
   * create a new line in the textarea.
   * 
   * @function handleEnter
   * @param {event} e
   */
  const handleEnter = e => {
    if (e.code === 'Enter' && !e.shiftKey && submitOnEnter) {
      e.preventDefault();
      
      onSubmit(e)
    }
  }
    
  return (
    <div
      className="autogrow"
      data-text={defaultValue || ''}
      ref={wrapper}
    >
      <textarea
        aria-required={required ? 'true' : 'false'}
        className="autogrow__text"
        data-errormsg={errorMsg || null}
        defaultValue={defaultValue || ''}
        disabled={disabled ? true : false}
        id={id}
        onKeyDown={submitOnEnter ? handleEnter : null}
        onInput={handleInput}
        placeholder={placeholder}
        required={required ? true : false}
      />
    </div>
  )
}

AutoGrow.displayName = 'AutoGrow';

export default AutoGrow;